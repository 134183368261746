import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import MainPage from "./pages/Main";
import { useWallet, UseWalletProvider } from "use-wallet";
import { useEffect } from "react";
import config from "./config";
import Web3 from "web3";

function App() {
  useEffect(() => {
    const ref = new URLSearchParams(window.location.search).get("ref");

    if (ref && Web3.utils.isAddress(ref)) {
      localStorage.setItem("ref", Web3.utils.toChecksumAddress(ref));
    }

    let getAddress = window.location.href.split("?ref=")[1];

    if (!window.location.href.includes("?ref=")) {
      localStorage.setItem("ref", "0x04fb8068d32ccC094b766B7aFBe76588A01d325F");
    }
  }, []);

  return (
    <div className="App">
      <UseWalletProvider
        chainId={config.CHAINID}
        connectors={
          {
            // This is how connectors get configured
          }
        }
      >
        <MainPage />
      </UseWalletProvider>
    </div>
  );
}

export default App;
