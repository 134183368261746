export default {
  CONTRACT_ADDRESS: "0x9fAFbd9cC0D8e82892540df923EE6Bc943A81E6F", //staking contract address
  BUSD_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", // usdt token address
  DEFAULT_REFERRAL: "0x83Fd42D6D35e5B3Fe0545206fA28F86E125E6eeb", // default referrer
  DONATE_ADDRESS: "0x83Fd42D6D35e5B3Fe0545206fA28F86E125E6eeb",

  OVERFLOW_WITHDRAWTIME: 8 * 24 * 60 * 60,
  ONE_DAY_WITHDRAWTIME: 24 * 60 * 60,

  BASE_PROFIT_PERCENT: 126,
  CHAINID: 56,
};
